
.card-border-point{
    position: relative;
    &::after{
        content: "";
        display: block;
        position: absolute;
        width: 2px;
        height: 100%;
        left: 0;
        top: 0;
        background: var(--v-primary-base);
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

