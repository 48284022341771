
.lawyer-view {
    &__body {
        .lawyer-img {
            margin-bottom: calc(var(--page-section-padding-y-sm) * -1);
        }
        &-contents {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &-tit {
            padding-bottom: var(--tit-wrap-padding-bottom-lg);
        }
    }
}
@media (min-width: 576px) {
    .lawyer-view {
        &__body {
            .lawyer-img {
                margin-bottom: 0;
                bottom: calc(var(--page-section-padding-y-sm) * -1);
            }
        }
    }
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .lawyer-view {
        &__body {
            &-contents {
                min-height: 536px;
            }
        }
    }
}
@media (min-width: 1200px) {
}
