
.construction-bottom{
    position: relative;
    &__bg{
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
        transform: translateY(var(--page-section-padding-y));
    }
}
