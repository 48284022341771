
.lawyer-item{
    display: block;
    position: relative;
    &__contents{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: rgba(188, 110, 51, .7);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        transition: .15s ease-out;
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .lawyer-item:hover{
        .lawyer-item__contents{
            background-color: var(--v-primary-base);
        }
    }
}
@media (min-width:1200px){
}

