
.card-row{
    &__image{
        min-width: calc(100% + 12px);
        position: absolute;
        top: 0;
        right: 0;
        &-wrap{
            overflow: visible;
            position: relative;            
        }
    }
}
.card-rows>.card-row-wrap:nth-child(2n -1) .card-row{
    .card-row__image{
        right: unset;
        left: 0;
    }
}
.card-rows>.card-row-wrap:not(:last-child){
    margin-bottom: 50px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .card-rows>.card-row-wrap:nth-child(2n - 1) .card-row{
        flex-direction: row-reverse;
    }
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 70px;
    }
}
@media (min-width:1024px){
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 80px;
    }
}
@media (min-width:1200px){
    .card-rows>.card-row-wrap:not(:last-child){
        margin-bottom: 70px;
    }
}
@media (min-width:1544px){
    .card-row{
        &__image{
            min-width: calc((100vw - 180px) / 2);
        }
    }
}

