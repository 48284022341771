
.page-section {
    width: 100%;
    position: relative;
    padding: var(--page-section-padding-y) 0;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .container {
        position: relative;
        z-index: 1;
    }
    &--xs {
        padding: var(--page-section-padding-y-xs) 0;
    }
    &--sm {
        padding: var(--page-section-padding-y-sm) 0;
    }
    &--first {
        padding-top: var(--page-section-padding-y);
        // margin-top: calc(var(--contents-padding-top) * -1);
    }
    &--last {
        padding-bottom: var(--page-section-padding-y);
        // margin-bottom: calc(var(--contents-padding-bottom) * -1);
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 50%;
        -webkit-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        transform: translate(-50%, 0);
        z-index: -1;
        display: block;
        width: 100vw;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    &.text-bg{
        background-position: center var(--page-section-padding-y);
        background-size: 100% auto;
        &--2{
            background-position: right bottom;
            background-size: 100% auto;
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
@media (min-width:1544px){
    .page-section {
        &.text-bg{
            &--2{
                background-size: auto 100%;
            }
        }
    }
}

