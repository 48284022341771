
.page-section--slide{
    position: relative;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
@media (min-width:1440px){
    .page-section--slide{
        &__title{
            position: absolute;
            left: 12px;
            top: 12px;
            z-index: 2;
        }
    }
}
